<template>
    <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="180" />
        <el-table-column prop="name" label="姓名" width="180" />
        <el-table-column prop="phone" label="电话" />
        <el-table-column prop="email" label="邮箱" />
        <el-table-column prop="gender" label="性别" />
        <el-table-column prop="other" label="其他" />
    </el-table>
</template>

<script>
export default {
    created: function () {
        this.$http.get('/userinfos')
            .then(response => {
                console.log(response)
                this.tableData = response.data
            })
    },
    data: function () {
        return {
            tableData: [

            ]
        }
    }
}
</script>