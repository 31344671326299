<template>
    <i class="fa fa-codiepie fa-3x  custom-icon"></i>
    <h2 >请填写您的联系方式</h2>
    <h3 >资深移民顾问将为您提供一对一VIP服务</h3>

    <el-form :model="form" label-position="top" label-width="100px" class="centered-div">
        <!-- 1.输入框 -->
        <el-form-item prop="name" label="姓名:" :rules="[
            {
                required: true,
                message: '请输入姓名',
                trigger: 'blur',
            }
        ]" >
            <el-input v-model="form.name" />
        </el-form-item>

        <!-- 校验手机号rules -->
        <!-- :rules="[
            {
                required: true,
                message: '请输入手机号',
                trigger: 'blur',
            },
            {
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号',
                trigger: ['blur', 'change'],
            },
        ]" -->
        <el-form-item prop="phone" label="手机号:" :rules="[
            {
                required: true,
                message: '请输入手机号',
                trigger: 'blur',
            },
            {
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号',
                trigger: ['blur', 'change'],
            },
        ]" >
            <el-input v-model="form.phone" />
        </el-form-item>
        
        <!-- 校验email rules -->
        <!-- :rules="[
            {
                required: true,
                message: '请输入邮箱',
                trigger: 'blur',
            },
            {
                type: 'email',
                message: '请输入正确的邮箱',
                trigger: ['blur', 'change'],
            },
        ]" -->
        <el-form-item prop="email" label="邮箱:" >
            <el-input v-model="form.email" />
        </el-form-item>



        <!-- 2.单选列表 -->
        <!-- <el-form-item label="学历">
            <el-select v-model="form.education" placeholder="请选择您的学历">
                <el-option label="专科" value="专科" />
                <el-option label="本科" value="本科" />
                <el-option label="硕士" value="硕士" />
                <el-option label="博士" value="博士" />
            </el-select>
        </el-form-item> -->

        <!-- 3.开始结束日期选择 -->
        <!-- <el-form-item label="Activity time">
            <el-col :span="11">
                <el-date-picker v-model="form.date1" type="date" placeholder="Pick a date" style="width: 100%" />
            </el-col>
            <el-col :span="2" class="text-center">
                <span class="text-gray-500">-</span>
            </el-col>
            <el-col :span="11">
                <el-time-picker v-model="form.date2" placeholder="Pick a time" style="width: 100%" />
            </el-col>
        </el-form-item> -->

        <!-- 4.Toogle button -->
        <!-- <el-form-item label="Instant delivery">
            <el-switch v-model="form.delivery" />
        </el-form-item> -->

        <!-- 5.多选checkbox -->
        <!-- <el-form-item label="Activity type">
            <el-checkbox-group v-model="form.type">
                <el-checkbox label="Online activities" name="type" />
                <el-checkbox label="Promotion activities" name="type" />
                <el-checkbox label="Offline activities" name="type" />
                <el-checkbox label="Simple brand exposure" name="type" />
            </el-checkbox-group>
        </el-form-item> -->

        <!-- 6.单选checkbox -->
        <el-form-item label="性别:">
            <el-radio-group v-model="form.gender">
                <el-radio label="男" />
                <el-radio label="女" />
            </el-radio-group>
        </el-form-item>

        <el-form-item label="备注:">
            <el-input v-model="form.other" type="textarea" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit" class="centered-div2">提交</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import { reactive } from 'vue'
import axios from "axios"

export default {
    name: "coll",
    data: function () {
        return {
            form: {
                age: '',
                name: '',
                phone: '',
                email: '',
                education: '',
                gender: '',
                other: '',
                region: '',

                // date1: '',
                // date2: '',
                // delivery: false,
                // type: [],
                // resource: '',
                // other: '',
            }
        }
    },

    methods: {
        isFormValid() {

            // 在这里添加表单信息的非空判断逻辑
            // 例如，检查姓名、手机号和邮箱是否为空np
            return (
                this.form.name.trim() !== '' &&
                this.form.phone.trim() !== '' 
                // 添加其他表单项的判断条件
            );
        },
        onSubmit() {
            if (this.isFormValid()) {

            } else {
                alert("请填写所有必填项")
                return

            }
            console.log('submit!')
            console.log(this.form)

            this.$http.post('/submit', this.form)
                .then(response => {
                    console.log(response)
                    // alert('提交成功')
                    this.$router.push({ path: '/ok' })
                    this.form = {
                        name: '',
                        phone: '',
                        email: '',
                        education: '',
                        gender: '',
                        other: '',
                        region: '',
                    }
                })
                .catch(error => {
                    // alert('提交失败')
                    this.$router.push({ path: '/ok' })
                    console.log(error)
                })

        },


    },
    created: function () {



    },
    mounted: function () {

    },





}
</script>
<style>
.centered-div {
    margin: auto;
    text-align: center;
    max-width: 40%;
    margin-top: 5%;

    justify-content: center;
    /* 水平居中对齐 */
}
/* 手机端适配 */
@media (max-width: 600px) {
    .centered-div {
        max-width: 80%;
      }
    }

.centered-div2 {
    margin: auto;
    display: block;
}

/* 自定义图标颜色的样式 */
.custom-icon {
    color: #3498db;
    /* 设置图标颜色为蓝色 */
}
</style>