import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import 'font-awesome/css/font-awesome.min.css'
import router from './routers/Index'


const app = createApp(App)

//配置请求根路径
// axios.defaults.baseURL="http://8.141.6.225:8088"
axios.defaults.baseURL="https://collect.ovsea.cn:8089"
// axios.defaults.baseURL="http://localhost:8088"
// 将axios作为全局的自定义属性，每个组件内部可以直接访问（$http）
app.config.globalProperties.$http=axios
app.use(router)
app.use(ElementPlus)



app.mount('#app')
