<template>
 
    <div >
      <h2>感谢参与调查问卷！</h2>
      <p>我们非常感谢您的时间和参与。</p>
      
      <!-- 在这里可以添加其他感谢消息、调查结果摘要或其他信息 -->
      
      <router-link to="/">返回首页</router-link>
    </div>
</template>
<style >
.ok{
  width: 50%;
  background-color: aqua;
}

</style>