<template>
  <div >
    <router-view></router-view>
   
  </div>
  <!-- <userlist /> -->
</template>

<script>
import coll123 from './components/collect.vue'
import userlist from './components/UserList.vue'
export default {
  name: 'App',
  components: {
    coll123,
    userlist,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
 /* 手机端适配
 @media (max-width: 600px) {
      #app {
        max-width: 100%;
      }
    } */
/* 自定义图标颜色的样式 */
.custom-icon {
  color: #3498db;
  /* 设置图标颜色为蓝色 */
}
</style>
